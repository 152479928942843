import ILine from "../../models/line";
import IGame  from "../../models/game";
import { BaseCommand } from "../command";
import GameRepository from "../../data/game-repository";

class ListGamesCommand extends BaseCommand {
    private games: IGame[] = [
        { name: "Title", shortDescription: "ShortDesc", version: "Version", gameId: "Id (for Download)", download: "" }        
    ];

    constructor(args: string[]) {
        super(args);
    }

    internalHandle(): ILine[] {
        if (this.args.length > 0) {
            return [{ text: `Too many arguments!`, isUserInput: false, isError: true }];
        }

        const gameRepository:GameRepository = new GameRepository();
        this.games = [...this.games, ...gameRepository.getGames()];

        return this.games.map((game:IGame) => {
            return { text: `${this.leftSpacing(game.gameId, 20)}${this.leftSpacing(game.name, 30)}${this.leftSpacing(game.version, 10)}${this.leftSpacing(game.shortDescription, 50)}`, isUserInput: false, isError: false } as ILine
        });
    }
}

export default ListGamesCommand;