import ILine from "../../models/line";
import { BaseCommand } from "../command";

class HelpCommand extends BaseCommand {
    constructor(args: string[]) {
        super(args);
    }


    internalHandle(): ILine[] {
        if (this.args.length > 1) {
            return [{ text: `Too many arguments!`, isUserInput: false, isError: true }];
        }

        if (this.args.length == 0) {
            return [
                { text: `Enter help "command" for command specific help.`, isUserInput: false, isError: false },
                { text: `Available commands:`, isUserInput: false, isError: false },
                { text: `${this.leftSpacing('help')}Displays the available command list`, isUserInput: false, isError: false },
                { text: `${this.leftSpacing('list-games')}Displays a list of my games available for download`, isUserInput: false, isError: false },
                { text: `${this.leftSpacing('download-game')}Downloads the selected game`, isUserInput: false, isError: false },
                { text: `${this.leftSpacing('current-work')}Shows information about my current work`, isUserInput: false, isError: false },
                { text: `${this.leftSpacing('quote')}Displays the quote of the day`, isUserInput: false, isError: false },
            ];
        }

        let result: ILine[] = [];
        switch (this.args[0]) {
            case "help":
                result = [{ text: `You are here.`, isUserInput: false, isError: false }];
                break;
            case "quote":
                result = [{ text: `Shows you the quote of the day.`, isUserInput: false, isError: false }];
                break;
            case "list-games":
                result = [{ text: `Shows you the list of all of my games I finished. You can download each game with the command "download-game".`, isUserInput: false, isError: false }];
                break;
            case "download-game":
                result = [
                    { text: `Starts the download of the selected game.`, isUserInput: false, isError: false },
                    // { text: ` `, isUserInput: false, isError: false },
                    { text: `download-game GAME_NAME`, isUserInput: false, isError: false },
                    // { text: ` `, isUserInput: false, isError: false },
                    { text: `${this.leftSpacing('GAME_NAME')}The name of the game`, isUserInput: false, isError: false },
                ];
                break;
            default:
                result = [{ text: `Unknown command! Type 'help' for a list of commands.`, isUserInput: false, isError: true }];
                break;
        }
        return result;
    }
}

export default HelpCommand;
