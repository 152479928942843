import React from "react";

interface IProps {
}

interface IState {
}

class MobileNotSupported extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <>
            <span>Mobile browsers currently not supported.</span>
        </>;
    }
}

export default MobileNotSupported;