import React from "react";

interface IProps {
    text: string;
}

interface IState {

}

class ConsoleString extends React.Component<IProps, IState> {
    render() {
        return <React.Fragment>
            <span className="write">&gt;&nbsp;</span>
            <span className="write"><pre>{this.props.text}</pre></span>
        </React.Fragment>;
    }
}

export default ConsoleString;