import React from "react";

interface IProps {
    cursor: string;
    input: string;
}

interface IState {
}

class Cmd extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const {input} = this.props;
        return <React.Fragment>
            <span className="write">&gt;&nbsp;{input}</span><span className="cursor">{this.props.cursor}</span>
        </React.Fragment>;
    }
}

export default Cmd;