import React from "react";

interface IProps {
    delay: number;
    maxDelay?: number;
    cursor: string;
    children: string;
    isError: boolean;

    animationDone?: () => void;
}

interface IState {
    text: string;
    display: string;
    pos: number;
    delay: number;
    animationDone: boolean;
}

class TimedString extends React.Component<IProps, IState> {
    /**
     *
     */
    constructor(props: IProps) {
        super(props);

        let delay = props.delay;
        if (props.maxDelay) {
            delay = this.randomNumber(delay, props.maxDelay);
        }

        this.state = {
            text: props.children,
            display: '',
            pos: -1,
            delay,
            animationDone: false
        };
    }

    randomNumber(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    componentDidMount() {
        setTimeout(() => {
            this.appendCharacter();
        }, this.state.delay * 1000);
    }

    // append one character at a time to display property
    appendCharacter() {
        let { text, display } = this.state;
        let { pos } = this.state;
        const { delay } = this.state;
        if (display != text) {
            pos = pos + 1;
            display = display + text[pos];
            this.setState({ display, pos });
            setTimeout(() => {
                this.appendCharacter();
            }, delay * 1000);
            return;
        }

        this.setState({animationDone: true}, this.props.animationDone);
    }

    render() {
        const { display, animationDone } = this.state;
        return <React.Fragment><span className={this.props.isError ? "error" : "read"}>{display}</span>{!animationDone && <span className={this.props.isError ? "error cursor" : "cursor"}>{this.props.cursor}</span>}</React.Fragment>;
    }
}

export default TimedString;