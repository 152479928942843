import IGame  from "../models/game";

class GameRepository {
    private games: IGame[] = [];

    constructor() {
        this.addGame({ name: "Dodger", shortDescription: "A block-avoiding game", version: "1.0", gameId: "12-01", download: "12-01-dodger/setup.exe" });
    }

    getGames() {
        return this.games;
    }

    addGame(game: IGame) {
        this.games.push(game);
    }

    getGame(id: string): IGame | undefined {
        return this.games.find((game: IGame) => game.gameId === id);
    }
}

export default GameRepository;