import ILine from "../../models/line";
import IQuote  from "../../models/quote";
import { BaseCommand } from "../command";

class QuoteCommand extends BaseCommand {
private quotes: IQuote[] = [
    { text: "I'm not a great programmer; I'm just a good programmer with great habits.", author: "Kent Beck" },
    { text: "First, solve the problem. Then, write the code.", author: "John Johnson" },
    { text: "Measuring programming progress by lines of code is like measuring aircraft building progress by weight.", author: "Bill Gates" },
    { text: "There are 2 hard problems in computer science: cache invalidation, naming things, and off-by-1 errors.", author: "Leon Bambrick" },
    { text: "Nine people can't make a baby in a month.", author: "Fred Brooks" },
    { text: "If debugging is the process of removing software bugs, then programming must be the process of putting them in.", author: "Edsger Dijkstra" },
    { text: "The first 90% of the code accounts for the first 90% of the development time. The remaining 10% of the code accounts for the other 90% of the development time.", author: "Tom Cargill" },
    { text: "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.", author: "Martin Fowler" },
    { text: "Walking on water and developing software from a specification are easy if both are frozen.", author: "Edward V Berard" },
    { text: "It always takes longer than you expect, even when you take into account Hofstadter's Law.", author: "Hofstadter's Law" },
    { text: "Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live.", author: "Rick Osborne" },
    { text: "In theory, there is no difference between theory and practice. But, in practice, there is.", author: "Jan L. A. van de Snepscheut" },
    { text: "The best thing about a boolean is even if you are wrong, you are only off by a bit.", author: "Anonymous" }
];

    constructor(args: string[]) {
        super(args);
    }

    internalHandle(): ILine[] {
        if (this.args.length > 0) {
            return [{ text: `Too many arguments!`, isUserInput: false, isError: true }];
        }

        let quote = this.getRandomQuote();
        return [{ text: `"${quote.text}" - ${quote.author}`, isUserInput: false, isError: false }];
    }

    private getRandomQuote(): IQuote {
        let quote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
        return quote;
    }
    
}

export default QuoteCommand;