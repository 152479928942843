import ILine from "../../models/line";
import IGame  from "../../models/game";
import { BaseCommand } from "../command";
import GameRepository from "../../data/game-repository";

class DownloadGameCommand extends BaseCommand {
    constructor(args: string[]) {
        super(args);
    }

    internalHandle(): ILine[] {
        if (this.args.length > 1) {
            return [{ text: `Too many arguments!`, isUserInput: false, isError: true }];
        }
        if (this.args.length == 0) {
            return [{ text: `You must specify a game to download.`, isUserInput: false, isError: true }];
        }

        const gameRepository:GameRepository = new GameRepository();
        let game:IGame|undefined = gameRepository.getGame(this.args[0]);
        if (!game) {
            return [{ text: `Game not found.`, isUserInput: false, isError: true }];
        }

        this.download(game);
        return [{ text: `Downloading ${game.name}...`, isUserInput: false, isError: false }];
    }

    download = (game: IGame) => {
        const link = document.createElement("a");
        link.href = `https://builds.meanpixel.de/${game.download}`
        link.setAttribute("download", `${game.name}-Installer.exe`);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
            link.parentNode?.removeChild(link);
        }, 3000);
    }
}

export default DownloadGameCommand;