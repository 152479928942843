import ILine from "../../models/line";
import { BaseCommand } from "../command";

class CurrentWorkCommand extends BaseCommand {
private lines: ILine[] = [
    { text: "Currently I am working on some prototypes for an upcoming game. ", isUserInput: false, isError: false },
    { text: "The current prototype is a dungeon crawler.", isUserInput: false, isError: false },
    { text: "There are two goals to achieve with this prototype:", isUserInput: false, isError: false},
    { text: "- to learn procedural generation of rooms and hallways", isUserInput: false, isError: false },
    { text: "- to combine the generated assets to a working level", isUserInput: false, isError: false }
];

    constructor(args: string[]) {
        super(args);
    }

    internalHandle(): ILine[] {
        if (this.args.length > 0) {
            return [{ text: `Too many arguments!`, isUserInput: false, isError: true }];
        }
        return this.lines;
    }
}

export default CurrentWorkCommand;
