import ILine from "../models/line";

export interface ICommand {
    internalHandle?: () => ILine[];
}

export abstract class BaseCommand implements ICommand {
    protected args: string[];
    protected leftColumnWidth: number = 24;
    constructor(args: string[]) {
        this.args = args;
    }

    protected leftSpacing = (text: string, length?: number) : string => {
        if (!length) {
            length = this.leftColumnWidth;
        }
        return text.length < length ? this.leftSpacing(text + ".", length) : text;
    }

    handle = (): ILine[] => {
        return this.internalHandle();
    };

    abstract internalHandle() : ILine[];
}