import React from "react";
import MobileNotSupported from "../components/mobile-not-supported/mobile-not-supported";
import MeanOs from "../components/mean-os/mean-os";
import { BrowserView, MobileView } from 'react-device-detect';

class App extends React.Component {
    render() {
        return <React.Fragment>
            <BrowserView>
                <MeanOs />
            </BrowserView>
            <MobileView>
                <MobileNotSupported />
            </MobileView>
        </React.Fragment>;
    }
}

export default App;

// body > main > div > aside > a:nth-child(1) > img
// #app > div > main > div > aside > a:nth-child(1) > img
