import React from "react";
import ConsoleString from "../console-string/console-string";
import Cmd from "../cmd/cmd";
import TimedString from "../timed-string/timed-string";
import CommandParser from "../../utils/command-parser";
import ILine from "../../models/line";

interface IProps {
}

interface IState {
    lines: ILine[],
    osLines: ILine[],
    input: string,
    lastInput: string;
    renderCommandLine: boolean
}

class MeanOs extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        let  osLines: ILine[] = [
            { isError: false, isUserInput: false, text: `Welcome to MeanOS v0.${this.randomNumber(1, 9)}.${this.randomNumber(0, 9)}.${this.randomNumber(0, 9)}!` },
            { isError: false, isUserInput: false, text: `---------------------------`},
            { isError: false, isUserInput: false, text: `Type 'help' for a list of commands.`}
        ];

        this.state = {
            osLines,
            lines: [],
            input: '',
            lastInput: '',
            renderCommandLine: false
        };
    }

    submit = () => {
        const { input, lines } = this.state;
        this.setState({ 
            lastInput: input,
            lines: [...lines, { text: input, isUserInput: true, isError: false }]
        }, () => {
            let commandParser = new CommandParser();
            let result = commandParser.handle(input);
            this.setState({osLines: result}, () => this.next());
            // this.setState({
            //     lines: [...lines, { text: input, isUserInput: true, isError: false }, ...result],
            // });
        });
    }

    randomNumber(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    next = () => {
        const { lines } = this.state;
        let { osLines } = this.state;
        if (osLines.length == 0) {
            this.setState({ renderCommandLine: true });
            return;
        }

        if (osLines.length > 0) {
            let line = osLines.shift();
            this.setState({
                lines: [...lines, line!],
                osLines,
                renderCommandLine: false
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown, false);
        this.next();
    }

    handleKeyDown = (ev: KeyboardEvent) => {
        const { renderCommandLine } = this.state;
        if (!renderCommandLine) {
            return;
        }

        let { input, lastInput } = this.state;

        switch (ev.key) {
            case 'Backspace':
                input = input.slice(0, -1);
                break;
            case 'Escape':
                input = '';
                break;
            case 'A':
            case 'B':
            case 'C':
            case 'D':
            case 'E':
            case 'F':
            case 'G':
            case 'H':
            case 'I':
            case 'J':
            case 'K':
            case 'L':
            case 'M':
            case 'N':
            case 'O':
            case 'P':
            case 'Q':
            case 'R':
            case 'S':
            case 'T':
            case 'U':
            case 'V':
            case 'W':
            case 'X':
            case 'Y':
            case 'Z':
            case 'a':
            case 'b':
            case 'c':
            case 'd':
            case 'e':
            case 'f':
            case 'g':
            case 'h':
            case 'i':
            case 'j':
            case 'k':
            case 'l':
            case 'm':
            case 'n':
            case 'o':
            case 'p':
            case 'q':
            case 'r':
            case 's':
            case 't':
            case 'u':
            case 'v':
            case 'w':
            case 'x':
            case 'y':
            case 'z':
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
            case '.':
            case '#':
            case '-':
            case '_':
            case ' ':
                input += ev.key;
                break; case 'A':
            case 'Enter':
                this.submit();
                input = '';
                break;
            case 'ArrowUp':
                input = lastInput;
                break;
            default:
                console.log(ev.key);
                break;
        }

        if (input == '' || input.match(/[a-zA-Z0-9\.\-\_]/)) {
            this.setState({ input });
        }
    }

    render() {
        let { lines, renderCommandLine, input } = this.state;
        return <React.Fragment>
            {lines.map(line => {
                if (line.isUserInput) {
                    return <div key={lines.indexOf(line)}><ConsoleString text={line.text} /></div>;
                }
                return (<div key={lines.indexOf(line)}><TimedString cursor="_" delay={0.02} isError={line.isError} animationDone={this.next}>{line.text}</TimedString></div>);
            })}
            {renderCommandLine && <Cmd cursor="_" input={input} />}
        </React.Fragment>;
    }
}

export default MeanOs;