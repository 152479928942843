import CurrentWorkCommand from "../commands/current-work/current-work-command";
import DownloadGameCommand from "../commands/download-game/download-game-command";
import HelpCommand from "../commands/help/help-command";
import ListGamesCommand from "../commands/list-games/list-games-command";
import QuoteCommand from "../commands/quote/quote-command";
import ICommand from "../models/command";
import ILine from "../models/line";


class CommandParser {
    private availableCommands: string[] = [
        'help', 'quote', 'list-games', 'download-game', 'current-work'
    ];

    handle(command: string): ILine[] {
        let result: ILine[] = [];
        let commandObj = this.parse(command);
        if (!this.availableCommands.some((s: string) => s == commandObj.name)) {
            return [{ text: `Unknown command! Type 'help' for a list of commands.`, isUserInput: false, isError: true }];
        }
        switch (commandObj.name) {
            case 'help':
                result = new HelpCommand(commandObj.args).handle();
                break;
            case 'quote':
                result = new QuoteCommand(commandObj.args).handle();
                break;
            case 'list-games':
                result = new ListGamesCommand(commandObj.args).handle();
                break;
            case 'download-game':
                result = new DownloadGameCommand(commandObj.args).handle();
                break;
            case 'current-work':
                result = new CurrentWorkCommand(commandObj.args).handle();
                break;
        }
        return result;
    }

    parse(command: string): ICommand {
        let args = command.split(' ');
        let commandName = args.shift();
        return {
            name: commandName,
            args: args
        };
    }
}

export default CommandParser;